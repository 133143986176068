import {
  ColDef,
  ColGroupDef,
  GetRowIdFunc,
  IRowNode,
  RowClassParams,
  SelectionChangedEvent
} from '@ag-grid-community/core'
import { AgGridReact } from '@ag-grid-community/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashCanXmark } from '@awesome.me/kit-5de77b2c02/icons/classic/regular'
import cx from 'classnames'
import dayjs from 'dayjs'
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import TradeConfirm from '../../components/Activity/TradeConfirm/TradeConfirm'
import ListTradingDepthDetailRenderer from '../../components/DepthOfMarket/ListTradingDepthDetailRenderer.tsx'
import gridStyles from '../../components/Grid/grid.module.scss'
import { formatCoupon } from '../../helpers/formatting'
import {
  deselectSecurityOrdersToCancel,
  listTradingSecuritiesFetch,
  selectListTradingSecurities
} from '../../store/listTrading/actions'
import {
  getListTradingSecurities,
  getSelectedRows,
  getWorkingOrderBySecurityId
} from '../../store/listTrading/selectors'
import {
  ListTradingSecurity,
  WorkingOrderFields
} from '../../store/listTrading/types'
import { getListTradeOrders } from '../../store/order/selectors'
import {
  fetchListTradingSecurities,
  unsubscribeListTrading
} from '../../store/securities/actions'
import { SecurityStaticData } from '../../store/securities/reducer'
import { getListTradingGridPage } from '../../store/securities/selectors'
import { updateColumnsOrder } from '../../store/settings/actions'
import { getListTradingColumnOrder } from '../../store/settings/selectors'
import { useAppDispatch, useAppSelector } from '../../store/types'
import { getCurrentTheme } from '../../store/userPreferences/selectors.ts'
import { COUPON, ISIN, ISSUER, MATURITY } from '../BondList/columnDefs'
import BestPriceOrSpreadRenderer from './cells/BestPriceOrSpreadRenderer'
import BestSizeRenderer from './cells/BestSizeRenderer'
import BuySellToggleRenderer from './cells/BuySellToggleRenderer'
import DefaultWorkingOrderFieldRenderer from './cells/DefaultWorkingOrderFieldRenderer.tsx'
import HideRowCell from './cells/HideRowCell'
import HideRowHeaderCell from './cells/HideRowHeaderCell'
import ListTradingSecurityFieldRenderer from './cells/ListTradingSecurityFieldRenderer'
import PriceEditor from './cells/PriceEditor'
import PriceRenderer from './cells/PriceRenderer'
import SelectOrdersToCancelHeaderCheckbox from './cells/SelectOrdersToCancelHeaderCheckbox'
import SelectSecurityOrdersToCancelCheckbox from './cells/SelectSecurityOrdersToCancelCheckbox'
import SizeEditor from './cells/SizeEditor'
import SizeOrInterestFieldRenderer from './cells/SizeOrInterestFieldRenderer'
import SpreadEditor from './cells/SpreadEditor'
import SpreadRenderer from './cells/SpreadRenderer'
import StatusRenderer from './cells/StatusRenderer'
import WorkingOrderFieldRenderer from './cells/WorkingOrderFieldRenderer'

import { Order } from '../../store/order/types.ts'
import DepthOfMarketControls from './cells/DepthOfMarketControls.tsx'
import styles from './grid.module.scss'
import { isPending, isTradeable } from './helpers.ts'
import ListTradingFilter, { emptyFilter, LTFilter } from './ListTradingFilter'

interface Props {
  watchlistId: number
}

export type TradingListContext = {
  watchlistId: number
  showOrderDetails: (id: string | null) => void
  setSecuritiesToHide: Dispatch<SetStateAction<number[]>>
  securitiesToHide: number[]
  hideRowHeaderState: 'checked' | 'unchecked' | 'indeterminate'
  toggleHideRowsHeaderCheck: () => void
}

const defaultColumn: ColDef = {
  autoHeaderHeight: true,
  // columnMenu: 'legacy', // coming in future version of ag grid
  editable: false,
  floatingFilter: true,
  lockPinned: true,
  menuTabs: ['columnsMenuTab'],
  resizable: false,
  sortable: false,
  suppressColumnsToolPanel: true,
  suppressPaste: true,
  minWidth: 10
}

const getRowId: GetRowIdFunc<SecurityStaticData> = ({ data }) => {
  return `${data?.id || ''}`
}

const invalidColId = 'no id provided'
const defaultColumns = ['Select', 'DepthTools']
const gridWidth = 1498
const applyColumnsOrder = (columns: ColDef[], userOrder: string[]) => {
  // flex doesn't work for pinned columns, so we need to calculate it
  let occupiedWidth = 0
  if (!userOrder.length) {
    return columns.map((col) => {
      const column = {
        ...col,
        suppressColumnsToolPanel: defaultColumns.includes(
          col.colId ?? invalidColId
        )
      }
      if (!col.pinned) {
        occupiedWidth += col.maxWidth ?? 10
      } else {
        column.width = gridWidth - occupiedWidth
      }
      return column
    })
  }
  const allColumns = [...defaultColumns, ...userOrder]
  const columnSort = (col1: ColDef, col2: ColDef) => {
    const pos1 = allColumns.indexOf(col1.colId ?? '')
    const pos2 = allColumns.indexOf(col2.colId ?? '')
    return pos1 - pos2
  }
  const mappedColumns = columns
    .map((col) => {
      const column = {
        ...col,
        hide: !allColumns.includes(col.colId ?? invalidColId),
        suppressColumnsToolPanel: defaultColumns.includes(
          col.colId ?? invalidColId
        )
      }
      if (col.colId !== 'Status') {
        occupiedWidth += column.hide ? 0 : column.maxWidth ?? 10
      } else {
        // 14px for scrollbar
        column.width = column.maxWidth = gridWidth - (occupiedWidth + 14)
      }
      return column
    })
    .sort(columnSort)
  const extraColumns = userOrder.includes('Status')
    ? []
    : [
        {
          colId: 'padding',
          headerName: '',
          hide: false,
          resizable: false,
          flex: 1,
          suppressMovable: true,
          suppressPaste: true,
          suppressHeaderMenuButton: true,
          suppressHeaderContextMenu: true
        }
      ]
  return [...mappedColumns, ...extraColumns]
}

const Grid = ({ watchlistId }: Props) => {
  const gridRef = useRef<AgGridReact<SecurityStaticData>>(null)
  const dispatch = useAppDispatch()
  const theme = useAppSelector(getCurrentTheme)
  const savedColumnOrder = useAppSelector(getListTradingColumnOrder)

  const getPageOfStaticSecurities = useAppSelector(getListTradingGridPage)
  const securityStaticData = getPageOfStaticSecurities(0)

  useEffect(() => {
    // listrading securities (orders)
    dispatch(listTradingSecuritiesFetch(watchlistId))
    // static data
    dispatch(fetchListTradingSecurities(watchlistId, 0))

    return () => {
      // clears grid data and unsubscribes to this wl
      dispatch(unsubscribeListTrading())
    }
  }, [watchlistId])

  const [orderToShow, setOrderToShow] = useState<string | null>(null)
  const tradeConfirmId = Number((orderToShow || '').replace('O', ''))

  // ------------------ managing selections ------------------ //
  const listTradingSecurities = useAppSelector(getListTradingSecurities)
  const workingOrderGetter = useAppSelector(getWorkingOrderBySecurityId)
  const enableSelectAll =
    listTradingSecurities.filter((security) => isTradeable(security)).length > 0

  const securitiesWithSelectedOrders = useAppSelector(getSelectedRows)

  const isRowSelectable = useCallback(
    (row: IRowNode<SecurityStaticData>) => {
      const securityId = row.data?.id
      if (!securityId) return false
      const ltSecurity = listTradingSecurities.find(
        (security) => security.id === securityId
      )
      if (!ltSecurity || typeof ltSecurity.isBid !== 'boolean') return false
      const size =
        (ltSecurity?.remainingInterest ||
          workingOrderGetter(securityId)?.size) ??
        0
      return (
        !!ltSecurity && isTradeable({ ...ltSecurity, remainingInterest: size })
      )
    },
    [listTradingSecurities, workingOrderGetter]
  )

  const onSelectionChanged = useCallback(
    ({ api }: SelectionChangedEvent<SecurityStaticData>) => {
      const selectedSecurities = api
        .getSelectedRows()
        .map((security) => security.id)
      dispatch(selectListTradingSecurities(selectedSecurities))
    },
    [listTradingSecurities]
  )

  useEffect(() => {
    const api = gridRef.current?.api
    if (!api) return
    // update checkbox in ui to match redux state
    api.forEachNode((node) => {
      if (!isRowSelectable(node) && node.id) {
        node.setSelected(false)
      } else {
        if (node.data) {
          const selectedState = securitiesWithSelectedOrders.includes(
            node.data.id
          )
          if (node.isSelected() !== selectedState) {
            node.setSelected(selectedState)
          }
        }
      }
    })
  }, [listTradingSecurities, securitiesWithSelectedOrders])

  // ------------------ filtering ------------------ //
  const [externalFilter, setExternalFilter] = useState<LTFilter>()

  const [securitiesToHide, setSecuritiesToHide] = useState([] as number[])
  const [hiddenSecurities, setHiddenSecurities] = useState([] as number[])

  const hideSecurities = useCallback(() => {
    setHiddenSecurities((oldSecurities) => [
      ...oldSecurities,
      ...securitiesToHide
    ])
    setSecuritiesToHide([])
    securitiesToHide.forEach((id) => {
      dispatch(deselectSecurityOrdersToCancel(id))
      if (gridRef.current?.api) {
        const node = gridRef.current.api.getRowNode(id.toString())
        if (node?.isSelected()) {
          node.setSelected(false)
        }
      }
    })
  }, [securitiesToHide, securitiesWithSelectedOrders, gridRef.current?.api])

  const onFilterChange = useCallback((filter: LTFilter) => {
    setExternalFilter(filter)
  }, [])
  const isExternalFilterPresent = useCallback(() => {
    return (
      !externalFilter ||
      externalFilter.completed !== emptyFilter.completed ||
      externalFilter.targetMet !== emptyFilter.targetMet ||
      externalFilter.pending !== emptyFilter.pending
    )
  }, [externalFilter])
  const getAggressOrders = useAppSelector(getListTradeOrders)
  const isCompleted = (order: Order) => order.status === 'accepted'
  const doesExternalFilterPass = useCallback(
    (node: IRowNode<SecurityStaticData>) => {
      if (!node.data) return false
      if (hiddenSecurities.includes(node.data.id)) return false
      if (!externalFilter) return true
      const workingOrder = workingOrderGetter(node.data.id)
      if (!workingOrder) return false
      const oppositeType = workingOrder.isBid ? 'buy' : 'sell'
      const orders = getAggressOrders(node.data.id, oppositeType, watchlistId)
      const ltSecurity = listTradingSecurities.find(
        (sec) => sec.id === node.data!.id
      )
      if (externalFilter.completed !== emptyFilter.completed) {
        switch (externalFilter.completed) {
          case 'completed':
            if (
              !orders.every(isCompleted) ||
              !orders.length ||
              ltSecurity?.remainingInterest !== 0
            ) {
              return false
            }
            break
          case 'partial':
            if (!orders.some(isCompleted)) return false
            if (
              !ltSecurity ||
              ltSecurity.remainingInterest === 0 ||
              ltSecurity.remainingInterest === ltSecurity.interest
            ) {
              return false
            }
            break
          case 'unfilled':
            if (orders.some(isCompleted)) return false
            break
          default:
            break
        }
      }
      if (externalFilter.targetMet !== 'all') {
        if (!isRowSelectable) return false
      }
      if (externalFilter.targetMet === 'amountAndSize') {
        if (ltSecurity) {
          if (!workingOrder.size) return false
          const bestTrade = ltSecurity.bestOrder
          if ((bestTrade?.size ?? 0) < workingOrder.size) return false
        }
      }
      if (externalFilter.pending) {
        if (!orders.some(isPending)) {
          return false
        }
      }
      return true
    },
    [
      externalFilter,
      hiddenSecurities,
      listTradingSecurities,
      workingOrderGetter,
      getAggressOrders,
      isRowSelectable,
      watchlistId
    ]
  )
  // ------------------ styling ------------------ //
  const hasSize = useCallback(
    (row: IRowNode<SecurityStaticData>) => {
      const securityId = row.data?.id
      if (!securityId) return false
      const ltSecurity = listTradingSecurities.find(
        (security) => security.id === securityId
      )
      return (
        !!ltSecurity &&
        (ltSecurity.bestOrder?.size ?? 0) > (ltSecurity.remainingInterest || 0)
      )
    },
    [listTradingSecurities]
  )
  const hasError = useCallback(
    (row: IRowNode<SecurityStaticData>) => {
      const securityId = row.data?.id
      if (!securityId) return false
      const ltSecurity = listTradingSecurities.find(
        (security) => security.id === securityId
      )
      return !!ltSecurity?.error
    },
    [listTradingSecurities]
  )
  const rowClassRules = useMemo(() => {
    return {
      [styles['tradeable-row']]: ({
        node
      }: RowClassParams<SecurityStaticData>) => node && isRowSelectable(node),
      [styles.hasSize]: ({ node }: RowClassParams<SecurityStaticData>) =>
        node && hasSize(node),
      [styles.errorRow]: ({ node }: RowClassParams<SecurityStaticData>) =>
        node && hasError(node)
    }
  }, [isRowSelectable, hasSize, hasError])

  // ------------------ columns ------------------ //
  const colDefs: Array<ColDef | ColGroupDef> = useMemo(() => {
    const createWorkingOrderFieldComparator =
      (field: WorkingOrderFields, isSpreadCol: boolean = false) =>
      (
        _v1: any,
        _v2: any,
        node1: IRowNode<SecurityStaticData>,
        node2: IRowNode<SecurityStaticData>
      ) => {
        if (node1.data && !node2.data) {
          return 1
        }
        if (!node1.data && node2.data) {
          return -1
        }
        if (!node1.data || !node2.data) return 0
        const wo1 = workingOrderGetter(node1.data.id)
        const wo2 = workingOrderGetter(node2.data.id)
        let multiplier1 = 1
        let multiplier2 = 1
        if (field === 'price') {
          if (wo1.isSpread !== isSpreadCol) {
            multiplier1 = -999
          }
          if (wo2.isSpread !== isSpreadCol) {
            multiplier2 = -999
          }
        }
        return (
          (Number(wo1?.[field] ?? 0) * multiplier1 -
            Number(wo2?.[field] ?? 0)) *
          multiplier2
        )
      }
    const buySellComparator = createWorkingOrderFieldComparator('isBid')
    const priceComparator = createWorkingOrderFieldComparator('price')
    const spreadComparator = createWorkingOrderFieldComparator('price', true)

    const createLtSecurityFieldComparator =
      (fieldName: keyof ListTradingSecurity) =>
      (
        _v1: any,
        _v2: any,
        node1: IRowNode<SecurityStaticData>,
        node2: IRowNode<SecurityStaticData>
      ) => {
        if (node1.data && !node2.data) {
          return 1
        }
        if (!node1.data && node2.data) {
          return -1
        }
        if (!node1.data || !node2.data) return 0
        const lts1 = listTradingSecurities.find((s) => s.id === node1.data!.id)
        const lts2 = listTradingSecurities.find((s) => s.id === node2.data!.id)
        return Number(lts1?.[fieldName] ?? 0) - Number(lts2?.[fieldName] ?? 0)
      }

    return applyColumnsOrder(
      [
        {
          colId: 'Select',
          checkboxSelection: true,
          headerCheckboxSelection: enableSelectAll,
          headerCheckboxSelectionFilteredOnly: true,
          resizable: false,
          showDisabledCheckboxes: true,
          maxWidth: 22,
          suppressHeaderContextMenu: true,
          suppressHeaderMenuButton: !savedColumnOrder.length
        },
        {
          cellRenderer: DepthOfMarketControls,
          colId: 'DepthTools',
          headerName: '',
          hide: false,
          resizable: false,
          suppressAutoSize: true,
          suppressPaste: true,
          maxWidth: 22,
          suppressHeaderMenuButton: true,
          suppressHeaderContextMenu: true
        },
        {
          colId: ISSUER,
          field: ISSUER,
          filter: true,
          headerName: 'Ticker',
          maxWidth: 65,
          sortable: true
        },
        {
          cellClass: cx('number'),
          colId: COUPON,
          headerName: 'Coupon',
          field: 'coupon',
          filter: true,
          maxWidth: 70,
          sortable: true,
          valueFormatter: ({ value: coupon }) =>
            coupon ? `${formatCoupon(coupon)}` : ''
        },
        {
          colId: MATURITY,
          field: 'maturityDate',
          filter: true,
          headerName: 'Maturity',
          cellClass: cx('number'),
          maxWidth: 75,
          sortable: true,
          valueFormatter: ({ value: maturityDate }) =>
            maturityDate ? dayjs(maturityDate).format('MM/YY') : ''
        },
        {
          colId: ISIN,
          field: 'isin',
          filter: true,
          headerName: 'ISIN',
          resizable: true,
          maxWidth: 110,
          sortable: true
        },
        {
          cellClass: styles.hideRowCell,
          cellRenderer: HideRowCell,
          colId: 'Hide',
          headerClass: styles.hideRowCell,
          headerComponent: HideRowHeaderCell,
          filter: false,
          headerName: '🗑',
          maxWidth: 30
        },
        {
          cellClass: `editable ${styles.buySellCell}`,
          cellRenderer: WorkingOrderFieldRenderer(
            watchlistId,
            'isBid',
            BuySellToggleRenderer
          ),
          colId: 'Buy_Sell',
          comparator: buySellComparator,
          editable: false,
          headerName: 'Buy/Sell',
          maxWidth: 75,
          sortable: true
        },
        {
          cellClass: 'editable number',
          colId: 'RemainingInterest',
          cellEditor: WorkingOrderFieldRenderer(
            watchlistId,
            'size',
            SizeEditor
          ),
          cellRenderer: SizeOrInterestFieldRenderer,
          comparator: createLtSecurityFieldComparator('remainingInterest'),
          editable: true,
          headerName: 'My Size',
          maxWidth: 65,
          singleClickEdit: true,
          sortable: true,
          wrapHeaderText: true
        },
        {
          cellClass: 'number',
          colId: 'Interest',
          editable: false,
          cellRenderer: ListTradingSecurityFieldRenderer(
            'interest' as const,
            DefaultWorkingOrderFieldRenderer
          ),
          comparator: createLtSecurityFieldComparator('interest'),
          headerName: 'Original Size',
          maxWidth: 90,
          wrapHeaderText: true
        },
        {
          cellClass: 'editable number',
          cellRenderer: WorkingOrderFieldRenderer(
            watchlistId,
            'price',
            SpreadRenderer
          ),
          cellEditor: WorkingOrderFieldRenderer(
            watchlistId,
            'price',
            SpreadEditor
          ),
          colId: 'TargetSpread',
          comparator: spreadComparator,
          editable: true,
          headerName: 'Limit Spread',
          maxWidth: 120,
          singleClickEdit: true,
          sortable: true
        },
        {
          cellClass: 'editable number',
          cellEditor: WorkingOrderFieldRenderer(
            watchlistId,
            'price',
            PriceEditor
          ),
          cellRenderer: WorkingOrderFieldRenderer(
            watchlistId,
            'price',
            PriceRenderer
          ),
          colId: 'TargetPrice',
          comparator: priceComparator,
          editable: true,
          headerName: 'Limit Price',
          maxWidth: 90,
          singleClickEdit: true,
          sortable: true
        },
        {
          cellClass: 'number',
          colId: 'Completed',
          headerName: 'Completed',
          editable: false,
          cellRenderer: ListTradingSecurityFieldRenderer(
            'completedAmt' as const,
            DefaultWorkingOrderFieldRenderer
          ),
          comparator: createLtSecurityFieldComparator('completedAmt'),
          maxWidth: 85,
          sortable: true
        },
        {
          cellClass: 'number bidOfferCellText amt',
          cellRenderer: ListTradingSecurityFieldRenderer(
            undefined,
            BestPriceOrSpreadRenderer
          ),
          colId: 'BestTrade',
          headerName: 'Best Px/Spr',
          maxWidth: 100
        },
        {
          cellClass: 'number bidOfferCellText size',
          cellRenderer: ListTradingSecurityFieldRenderer(
            undefined,
            BestSizeRenderer
          ),
          colId: 'BestSize',
          headerName: 'Best Size',
          maxWidth: 75
        },
        {
          cellClass: `${styles.selectCheckbox} ${styles.rightText}`,
          cellRenderer: SelectSecurityOrdersToCancelCheckbox,
          colId: 'Cancel',
          headerClass: `${styles.cancelHeader}`,
          headerComponent: SelectOrdersToCancelHeaderCheckbox,
          lockPinned: true,
          pinned: 'right',
          maxWidth: 85,
          wrapHeaderText: true
        },
        {
          cellRenderer: StatusRenderer,
          cellClass: styles.statusColumn,
          colId: 'Status',
          editable: false,
          headerClass: styles.statusColumn,
          headerName: 'Status',
          lockPinned: true,
          pinned: 'right',
          maxWidth: 500
        }
      ],
      savedColumnOrder
    )
  }, [
    watchlistId,
    enableSelectAll,
    savedColumnOrder,
    workingOrderGetter,
    listTradingSecurities
  ])

  const handleColumnDefsChange = useCallback(
    ({ api }: { api: AgGridReact['api'] }) => {
      const columnsOrder = api
        .getAllDisplayedColumns()
        .map((col) => col.getColId())
        .filter((id) => id && !defaultColumns.includes(id))
      dispatch(updateColumnsOrder('listTradingSettings', columnsOrder))
    },
    []
  )

  const pinnedRightWidth = useMemo(() => {
    const typechecked: ColDef[] = colDefs.filter(
      (c) => ('maxWidth' in c || 'width' in c) && !c.hide && !c.pinned
    )
    const width =
      gridWidth -
      typechecked.reduce((w, c) => {
        return w + (c.maxWidth || c.width || 0)
      }, 0)
    return {
      '--pinnedRightWidth': `${width}px`
    } as React.CSSProperties
  }, [colDefs])

  const context = useMemo<TradingListContext>(() => {
    const hideRowHeaderState =
      securityStaticData?.length === securitiesToHide.length
        ? 'checked'
        : securitiesToHide.length === 0
        ? 'unchecked'
        : 'indeterminate'
    return {
      watchlistId,
      showOrderDetails: setOrderToShow,
      securitiesToHide,
      setSecuritiesToHide,
      hideRowHeaderState,
      toggleHideRowsHeaderCheck: () => {
        switch (hideRowHeaderState) {
          case 'unchecked':
          case 'indeterminate':
            if (securityStaticData) {
              setSecuritiesToHide(
                securityStaticData.map((security) => security.id)
              )
            }
            break
          case 'checked':
            setSecuritiesToHide([])
        }
      }
    }
  }, [watchlistId, securitiesToHide, securityStaticData])

  useEffect(() => {
    // AG grid doesn't do this when the context changes, which makes sense bc it's an object
    gridRef.current?.api?.refreshCells({ columns: ['Hide'] })
    gridRef.current?.api?.refreshHeader()
  }, [context.hideRowHeaderState, securitiesToHide])

  return (
    <>
      <ListTradingFilter
        onFilterChange={onFilterChange}
        className={styles.ltFilter}
      >
        {!!securitiesToHide.length && (
          <button onClick={hideSecurities}>
            <FontAwesomeIcon
              icon={faTrashCanXmark}
              style={{ color: 'var(--primaryRed)' }}
            />
            Hide {securitiesToHide.length} rows
          </button>
        )}
      </ListTradingFilter>
      <div
        className={cx(
          styles.listTrading,
          gridStyles.gridDimensions,
          theme,
          gridStyles.gridStyle
        )}
        data-testid="list-trading-grid"
        style={pinnedRightWidth}
      >
        <AgGridReact<SecurityStaticData>
          ref={gridRef}
          getRowId={getRowId}
          context={context}
          rowData={securityStaticData}
          // editing
          reactiveCustomComponents={true}
          stopEditingWhenCellsLoseFocus={true}
          enterNavigatesVertically={true}
          enterNavigatesVerticallyAfterEdit={true}
          //  selection
          suppressRowClickSelection={true}
          rowSelection="multiple"
          isRowSelectable={isRowSelectable}
          onSelectionChanged={onSelectionChanged}
          // filter
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          //  columns
          defaultColDef={defaultColumn}
          columnDefs={colDefs}
          maintainColumnOrder={true}
          onColumnMoved={handleColumnDefsChange}
          onColumnVisible={handleColumnDefsChange}
          suppressColumnVirtualisation={true}
          // master/detail
          embedFullWidthRows={true}
          masterDetail={true}
          detailCellRenderer={ListTradingDepthDetailRenderer}
          detailRowHeight={170}
          //  stopping things
          suppressDragLeaveHidesColumns={true}
          suppressRowTransform={true}
          suppressScrollOnNewData={true}
          //  visual
          alwaysShowVerticalScroll={true}
          groupHeaderHeight={0}
          headerHeight={20}
          rowHeight={20}
          overlayLoadingTemplate="Loading securities…"
          rowClassRules={rowClassRules}
        />

        {orderToShow && (
          <TradeConfirm
            tradeConfirmId={tradeConfirmId}
            handleTradeConfirmClick={setOrderToShow}
          />
        )}
      </div>
    </>
  )
}

export default Grid
